<template>
    <div class="ll-loader">
        <div class="ll-loader__body">
            <div class="ll-loader__circle circle-front"></div>
            <div class="ll-loader__circle circle-rear"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "section-loader",
};
</script>
