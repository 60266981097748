<template>
    <div>
        <MainHeader />
        <RouterView class="ll-body" />
        <MainFooter class="ll-footer" />
        <PageLoader
            v-if="isPageLoading"
            :class="{none: !isShown}" />
    </div>
</template>

<script>
import MainHeader from "@/components/compHeader.vue";
import MainFooter from "@/components/compFooter.vue";
import PageLoader from "@/components/compLoader.vue";

export default {
    name: "ll-layout",
    components: {
        MainHeader,
        MainFooter,
        PageLoader,
    },
    data() {
        return {
            isPageLoading: true,
            isShown: true,
        };
    },
    created() {
        this.$router.beforeEach((to, from, next) => {
            this.toggleLoading(true);
            setTimeout(() => {
                document.title = "Lead Logic | " + (to.meta.title || "Ваш ключ к успеху в цифровом мире");
                next();
            }, 300);
        });

        this.$router.afterEach(() => {
            setTimeout(() => {
                this.isShown = false;
                setTimeout(() => {
                    this.isPageLoading = false;
                }, 400);
            }, 300);
        });
    },
    methods: {
        toggleLoading(state) {
            this.isPageLoading = state;
            this.isShown = state;
        },
    },
};
</script>
