<template>
    <header
        class="tp-header__wrapper"
        :class="{scrolled: isScrolled, opened: isMobile}">
        <div class="tp-header">
            <div class="tp-header__content container">
                <router-link
                    to="/"
                    class="tp-header__logo">
                    <img
                        class="tp-header__logo-image"
                        src="@/assets//icons/logo.svg"
                        alt="logo" />
                </router-link>

                <nav class="tp-header__nav">
                    <ul class="tp-header__nav-list">
                        <li
                            v-for="(item, index) in links"
                            :key="'elem' + index"
                            class="tp-navlink">
                            <component
                                :is="item.link ? 'router-link' : 'a'"
                                :to="item.link"
                                class="tp-navlink__label">
                                {{ item.label }}
                            </component>
                            <ul
                                v-if="item.children"
                                class="tp-navlink tp-dropdown">
                                <li
                                    v-for="(inneritem, index) in item.children"
                                    :key="'subelem' + index"
                                    class="tp-navlink tp-sublink">
                                    <component
                                        :is="inneritem.link ? 'router-link' : 'a'"
                                        :to="inneritem.link"
                                        class="tp-navlink__label tp-sublink__label">
                                        {{ inneritem.label }}
                                    </component>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>

                <div class="tp-header__action">
                    <CompButton
                        :llbtnText="'Оставить заявку'"
                        :llbtnLink="'/contacts'" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import {mapActions} from "vuex";
import CompButton from "@/components/compButton.vue";

export default {
    name: "component-header",
    components: {CompButton},
    data() {
        return {
            isScrolled: false,
            isMobile: false,
            links: [
                {
                    label: "О нас",
                    link: "/about",
                },
                {
                    label: "Портфолио",
                    link: "/portfolio",
                },
                {
                    label: "Услуги",
                    link: "/services",
                },
                {
                    label: "Карьера",
                    link: "/career",
                },
                {
                    label: "Блог",
                    link: "/blog",
                },
                {
                    label: "Контакты",
                    link: "/contacts",
                },
            ],
        };
    },
    mounted() {
        ["mousewheel", "scroll"].forEach((evt) => window.addEventListener(evt, this.handleScroll, false));
    },
    methods: {
        ...mapActions(["openModal"]),
        handleScroll() {
            let screenWidth = window.innerWidth;
            if (screenWidth < 577) return;

            if (window.pageYOffset > 100) {
                this.isScrolled = true;
            } else if (this.isScrolled && window.pageYOffset <= 100 && window.pageYOffset > 0) {
                return;
            } else {
                this.isScrolled = false;
            }
        },
        openMobile() {
            this.isMobile = !this.isMobile;
        },
    },
};
</script>
