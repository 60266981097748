import Vue from "vue";
import VueRouter from "vue-router";

// import comload from "@/components/compConstruction.vue";

Vue.use(VueRouter);

const mainRoutes = [
    {
        path: "/",
        name: "home",
        meta: {title: "Главная"},
        component: () => import(/* webpackChunkName: "home" */ "../views/home/home.vue"),
    },
    {
        path: "/about",
        name: "about",
        meta: {title: "О нас"},
        component: () => import(/* webpackChunkName: "about" */ "../views/about/about.vue"),
    },
    {
        path: "/portfolio",
        name: "portfolio",
        meta: {title: "Портфолио"},
        component: () => import(/* webpackChunkName: "portfolio" */ "../views/portfolio/portfolio.vue"),
    },
    {
        path: "/services",
        name: "services",
        meta: {title: "Услуги"},
        component: () => import(/* webpackChunkName: "services" */ "../views/services/services.vue"),
    },
    {
        path: "/career",
        name: "career",
        meta: {title: "Карьера"},
        component: () => import(/* webpackChunkName: "career" */ "../views/career/career.vue"),
    },
    {
        path: "/blog",
        name: "blog",
        meta: {title: "Блог"},
        component: () => import(/* webpackChunkName: "blog" */ "../views/blog/blog.vue"),
    },
    {
        path: "/contacts",
        name: "contacts",
        meta: {title: "Контакты"},
        component: () => import(/* webpackChunkName: "contacts" */ "../views/contacts/contacts.vue"),
    },
    {
        path: "/",
        name: "test",
        meta: {title: "Главная"},
        component: () => import(/* webpackChunkName: "home" */ "../views/test/test.vue"),
    },
];

const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    },
    mode: "history",
    base: process.env.BASE_URL,
    routes: [...mainRoutes],
});

export default router;
