<template>
    <component
        class="llbtn"
        :is="llbtnLink ? 'router-link' : 'button'"
        :to="llbtnLink"
        :target="getTarget(llbtnTarget)"
        :class="[llbtnType]">
        <!-- <img
            v-if="llbtnLeftIcon"
            class="llbtn__icon left"
            :src="require(`@/assets/icons/${llbtnLeftIcon}.svg`)"
            alt="button icon" /> -->
        <p class="llbtn__text">{{ llbtnText }}</p>
        <!-- <img
            v-if="llbtnRightIcon"
            class="llbtn__icon right"
            :src="require(`@/assets/icons/${llbtnRightIcon}.svg`)"
            alt="button icon" /> -->
    </component>
</template>

<script>
export default {
    name: "component-button",
    props: {
        llbtnText: {
            type: String,
            required: true,
        },
        llbtnLink: {
            type: String,
            default: null,
        },
        llbtnLeftIcon: {
            type: String,
        },
        llbtnRightIcon: {
            type: String,
        },
        llbtnTarget: {
            type: Boolean,
            default: false,
        },
        llbtnType: {
            type: String,
        },
    },
    methods: {
        // handleClick() {
        //     // Handle the click event when llbtnLink is not provided
        //     if (!this.llbtnLink) {
        //         // Perform custom action or emit an event
        //     }
        // },
        getTarget(target) {
            return target ? "_blank" : "self";
        },
    },
};
</script>
